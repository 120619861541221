import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useAuth } from './AuthContext';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
// import DownloadButton from './DownloadButton';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Visibility } from "@mui/icons-material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { keyboard } from "@testing-library/user-event/dist/keyboard";


const Extra = () => {
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sections, setSections] = useState({});
  const [activeSection, setActiveSection] = useState(null);

  // console.log("Final output", sections);

  const [isEditing, setIsEditing] = useState(false);
  const [subEditing, setsubEditing] = useState(false);
  const [editedSection, setEditedSection] = useState(null);
  const [isAddingSection, setIsAddingSection] = useState(false);
  const [newSectionName, setNewSectionName] = useState("");
  const [visibilty, setvisibilty] = useState(false);

  const [open, setOpen] = React.useState(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sectionToDelete, setSectionToDelete] = useState(null);

  const { token } = useAuth();
 
  console.log(`top on extra ${token}`);
  

  // const { token } = useAuth();

  const URL = "https://aihumanizer.pranathiss.com/qxbox/"

  

  const handleAddSection = () => {
    setIsAddingSection(true);
  };

  const handleCancelAddSection = () => {
    setIsAddingSection(false);
    setNewSectionName("");
  };

  const handleSaveSection = () => {
    if (newSectionName) {
      setSections((prevSections) => ({
        ...prevSections,
        [newSectionName]: {
          title: newSectionName,
          subpages: {},
        },
      }));
      setActiveSection(newSectionName);
      setNewSectionName("");
      setIsAddingSection(false);
    }
  };

  const handleSubmitbtn = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${URL}/generate_sitemap/`,
        { category: inputText }
      );
      if (response.status === 200) {
        setInputText("");
        setvisibilty(true);
        // const firstSection = Object.keys(sections)[0];
        // setActiveSection(firstSection);
        // console.log(firstSection,'//////////');
      }
      const apiData = response.data.website;

      console.log("api data from axios", apiData);

      const mappedSections = {};
      apiData.forEach((sectionObject) => {
        const [sectionKey, sectionValue] = Object.entries(sectionObject)[0];
        mappedSections[sectionKey] = sectionValue[0];
      });
      console.log("mapped", mappedSections);

      setSections(mappedSections);
      const firstSection = Object.keys(mappedSections)[0];
      setActiveSection(firstSection);
    } catch (err) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = (sectionKey) => {
    // console.log(sectionKey);
    setIsEditing(false);
    setActiveSection(sectionKey);
  };

  // useEffect(() => {
  //   const firstSectionKey = Object.keys(sections)[0];
  //   setActiveSection(firstSectionKey);
  // }, []);

  const handleEdit = (section) => {
    setIsEditing(true);
    setEditedSection({ ...section });
  };

  const handleSaveEdit = () => {
    if (editedSection && activeSection) {
      setSections((prevSections) => ({
        ...prevSections,
        [activeSection]: editedSection,
      }));
      setIsEditing(false);
    }
  };

  const handleEditChange = (field, value) => {
    // console.log("Updating field:", field);
    // console.log("New value:", value);

    setEditedSection((prev) => {
      const updatedValue =
        field === "keywords"
          ? value.split(", ").map((item) => item.trim())
          : value;
      console.log("Updated value for field:", updatedValue);

      return {
        ...prev,
        [field]: updatedValue,
      };
    });
  };

  const handleSubpageEditChange = (subpageKey, field, value) => {
    setEditedSection((prev) => ({
      ...prev,
      subpages: {
        ...prev.subpages,
        [subpageKey]: {
          ...prev.subpages[subpageKey],
          // Check if the field is 'keywords', and process it into an array
          [field]:
            field === "keywords"
              ? value.split(",").map((item) => item.trim())
              : value,
        },
      },
    }));
  };

  const handleOpenDeleteDialog = (sectionKey) => {
    setSectionToDelete(sectionKey);
    setDeleteModalOpen(true);
  };

  const handleClose = () => {
    setDeleteModalOpen(false);
    setSectionToDelete(null); // Reset the section to delete
  };

  
  const handleDelete = () => {
    if (sectionToDelete) {
      setSections((prevSections) => {
        const updatedSections = { ...prevSections };
        delete updatedSections[sectionToDelete];
        const remainingSectionKeys = Object.keys(updatedSections);
        
        let newActiveSection = null;
        if (remainingSectionKeys.length > 0) {
          const indexToDelete = Object.keys(prevSections).indexOf(sectionToDelete);
          if (indexToDelete > 0) {
            newActiveSection = Object.keys(prevSections)[indexToDelete - 1];
          } else {
            newActiveSection = remainingSectionKeys[0];
          }
        }

        setActiveSection(newActiveSection);
        return updatedSections;
      });
    }
    handleClose(); // Close the dialog after deletion
  };


  const handleDeleteSection = (sectionKey) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this section?"
    );

    if (confirmDelete) {
      setSections((prevSections) => {
        const updatedSections = { ...prevSections };
        const sectionKeys = Object.keys(prevSections);
        const indexToDelete = sectionKeys.indexOf(sectionKey);
        delete updatedSections[sectionKey];
        const remainingSectionKeys = Object.keys(updatedSections);

        let newActiveSection = null;
        if (remainingSectionKeys.length > 0) {
          if (indexToDelete > 0) {
            newActiveSection = sectionKeys[indexToDelete - 1];
          } else {
            newActiveSection = remainingSectionKeys[0];
          }
        }

        setActiveSection(newActiveSection);

        return updatedSections;
      });
    }
  };

  const handleDeleteSubpage = (subpageKey) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this sub-section?"
    );

    if (confirmDelete) {
      setSections((prevSections) => {
        const updatedSubpages = { ...prevSections[activeSection].subpages };
        delete updatedSubpages[subpageKey];

        return {
          ...prevSections,
          [activeSection]: {
            ...prevSections[activeSection],
            subpages: updatedSubpages,
          },
        };
      });
    }
  };

  const [isAddingSubpage, setIsAddingSubpage] = useState(false);
  const [newSubpageTitle, setNewSubpageTitle] = useState("");
  const [newSubpageUrl, setNewSubpageUrl] = useState("");
  const [newSubpageDescription, setNewSubpageDescription] = useState("");
  const [newSubpageKeywords, setNewSubpageKeyword] = useState("");
  const [newSubpageMeta_title, setNewSubpageMetatitle] = useState("");
  const [newSubpageMetadescription, setNewSubpageMetadescription] =
    useState("");
  const [newSubpageHeaders, setNewSubpageHeaders] = useState("");

  const handleSaveSubpage = () => {
    if (
      newSubpageTitle ||
      newSubpageUrl ||
      newSubpageDescription ||
      newSubpageKeywords ||
      newSubpageMeta_title ||
      newSubpageMetadescription ||
      newSubpageHeaders
    ) {
      setSections((prevSections) => ({
        ...prevSections,
        [activeSection]: {
          ...prevSections[activeSection],
          subpages: {
            ...prevSections[activeSection].subpages,
            [newSubpageTitle.toLowerCase().replace(/\s+/g, "_")]: {
              title: newSubpageTitle,
              url: newSubpageUrl,
              description: newSubpageDescription,
              keywords: [newSubpageKeywords],
              meta_title: newSubpageMeta_title,
              meta_description: newSubpageMetadescription,
              headers: newSubpageHeaders,
            },
          },
        },
      }));

      setNewSubpageTitle("");
      setNewSubpageUrl("");
      setNewSubpageDescription("");
      setNewSubpageKeyword("");
      setNewSubpageMetatitle("");
      setNewSubpageMetadescription("");
      setNewSubpageHeaders("");
      setIsAddingSubpage(false);
    }
  };


  const exportToExcel = async () => {
    const data = [];
  
    // Populate data array from sections
    Object.entries(sections).forEach(([sectionKey, section]) => {
      const sectionKeywords = Array.isArray(section.keywords)
        ? section.keywords.join(", ")
        : "";
  
      const sectionHeaders = Array.isArray(section.headers)
        ? section.headers.join(", ")
        : "";
  
      data.push({
        level: "Main Page",
        url: section.url,
        title: section.title,
        description: section.description,
        keywords: sectionKeywords,
        meta_title: section.meta_title,
        meta_description: section.meta_description,
        headers: sectionHeaders,
      });
  
      Object.entries(section.subpages || {}).forEach(
        ([subpageKey, subpage]) => {
          const subpageKeywords = Array.isArray(subpage.keywords)
            ? subpage.keywords.join(", ")
            : "";
  
          const subpageHeaders = Array.isArray(subpage.headers)
            ? subpage.headers.join(", ")
            : "";
  
          data.push({
            level: "Subpage",
            url: subpage.url,
            title: subpage.title,
            description: subpage.description,
            keywords: subpageKeywords,
            meta_title: subpage.meta_title,
            meta_description: subpage.meta_description,
            headers: subpageHeaders,
          });
        }
      );
    });
  
    // Create Excel file
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sections");
  
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
  
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  
    const file = new File([blob], "sections_data.xlsx", { type: blob.type });
  
    await sendDataToAPI(data);
  
    // Trigger local download
    saveAs(blob, "sections_data.xlsx");
  };
  


  const sendDataToAPI = async (data) => {
    
    console.log(`token in the DataToApi ${token}`);
    
    try {
      const response = await fetch(`${URL}/download/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Token ${token}`,
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        throw new Error("Failed to send data to the API");
      }
      console.log("Data successfully sent to API");
    } catch (error) {
      console.error("Error sending data to API:", error);
    }
  };


  const handleEditSubpage = (subpageKey, e) => {
    e.stopPropagation();
    setEditedSection((prevState) => {
      const updatedSubpages = { ...prevState.subpages };
      updatedSubpages[subpageKey].subEditing =
        !updatedSubpages[subpageKey].subEditing;
      return {
        ...prevState,
        subpages: updatedSubpages,
      };
    });
  };

 


  const sendToBackend = () => {
    setLoading(true);
    const formattedData = {
      website: Object.entries(sections).map(([sectionKey, section]) => {
        const formattedSection = {
          [sectionKey]: [
            {
              url: section.url ? section.url : "",
              title: section.title ? section.title : "",
              description: section.description ? section.description : "",
              keywords: Array.isArray(section.keywords)
                ? section.keywords
                : [""],
              meta_title: section.meta_title ? section.meta_title : "",
              meta_description: section.meta_description
                ? section.meta_description
                : "",
              headers: Array.isArray(section.headers) ? section.headers : [""],
            },
          ],
        };
        if (section.subpages) {
          formattedSection[sectionKey][0].subpages = Object.entries(
            section.subpages
          ).reduce((acc, [subpageKey, subpage]) => {
            acc[subpageKey] = {
              url: subpage.url ? subpage.url : "",
              title: subpage.title ? subpage.title : "",
              description: subpage.description ? subpage.description : "",
              keywords: Array.isArray(subpage.keywords)
                ? subpage.keywords
                : [""],
              meta_title: subpage.meta_title ? subpage.meta_title : "",
              meta_description: subpage.meta_description
                ? subpage.meta_description
                : "",
              headers: Array.isArray(subpage.headers) ? subpage.headers : [""],
            };
            return acc;
          }, {});
        }
        return formattedSection;
        
      }),
    };

    console.log(formattedData);

    
    fetch(`${URL}final_json/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedData),
    })

      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
          setLoading(false);
        }

        return response.json();
      })
      .then((data) => {
        console.log(data);
        
        const apiData = data.website;
        console.log("API data from fetch", apiData);
        const mappedSections = {};
        apiData.forEach((sectionObject) => {
          const [sectionKey, sectionValue] = Object.entries(sectionObject)[0];
          mappedSections[sectionKey] = sectionValue[0];
        });
        console.log("Mapped sections:", mappedSections);
        setLoading(false);
        setSections(mappedSections);
        const firstSection = Object.keys(mappedSections)[0];
        setActiveSection(firstSection);
        setIsButtonDisabled(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

 
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmitbtn()
    }
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        border: "1px solid #e6e6ee",
        padding: "30px",
        borderRadius: "5px",
        overflow: "hidden",
        boxSizing: "border-box",
      }}
    >
      <Grid item xs={2} sx={{ padding: "10px", border: "1px solid #e6e6ee" }}>
        <Box>
          <h2 style={{ color: "#000075", marginBottom: "25px" }}>
            Sitemap Generator
          </h2>
          <TextField
            label="Give a short description about the product"
            variant="outlined"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            fullWidth
            required
            onKeyDown={handleKeyDown}
          />
          <Button
            variant="contained"
            style={{ backgroundColor: "#000075" }}
            onClick={handleSubmitbtn}
            sx={{ marginTop: 2, marginBottom: 2 }}
          >
            Generate Sitemap
          </Button>

          {loading && (
            <Backdrop
              sx={(theme) => ({
                color: "#fff",
                zIndex: theme.zIndex.drawer + 1,
              })}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}

          {error && (
            <Typography sx={{ marginTop: 2, color: "red" }}>{error}</Typography>
          )}
          <div>
            {/* Display existing sections as buttons */}
            {Object.keys(sections).map((sectionKey) => (
              <Button
                key={sectionKey}
                fullWidth
                variant={
                  activeSection === sectionKey ? "contained" : "outlined"
                }
                onClick={() => handleButtonClick(sectionKey)}
                sx={{ mb: 2 }}
              >
                {sectionKey}
              </Button>
            ))}

            {/* Add Section Button */}

            <Dialog
              open={deleteModalOpen}
              onClose={handleClose}
              aria-labelledby="delete-dialog-title"
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle id="delete-dialog-title">Are you sure?</DialogTitle>
              <DialogContent>
                <Typography variant="body1">
                  Are you sure you want to delete this entry?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDelete}
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>

            {visibilty && (
              <Button
                fullWidth
                variant="contained"
                color="info"
                onClick={handleAddSection}
                sx={{ mb: 2 }}
              >
                Add Section
              </Button>
            )}

            {/* <Button
              fullWidth
              variant="contained"
              color="success"
              style={{marginTop: "20px"}}
              onClick={exportToExcel}
              sx={{ mb: 2 }}
            >
              Download Excel
            </Button> */}

            {/* <button
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mb: 2 }}
              onClick={exportToExcel}
            >
              Download Excel
            </button> */}

            {/* Input for new section */}

            {isAddingSection && (
              <div>
                <TextField
                  fullWidth
                  label="New Section Name"
                  value={newSectionName}
                  onChange={(e) => setNewSectionName(e.target.value)}
                  sx={{ mb: 2 }}
                  required
                />
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#000075" }}
                  onClick={handleSaveSection}
                  sx={{ mr: 2 }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancelAddSection}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>

          {visibilty && (
            <Button
              fullWidth
              variant="contained"
              color="success"
              style={{ marginTop: "20px" }}
              onClick={sendToBackend}
              disabled={isButtonDisabled}
              sx={{ mb: 2 }}
            >
              Update
            </Button>
          )}

          {visibilty && (
            <Button
              fullWidth
              variant="contained"
              color="success"
              onClick={exportToExcel}
              sx={{ mb: 2 }}
            >
              Download Excel
            </Button>
          )}

          {/* <DownloadButton/>  */}
        </Box>
      </Grid>
      <Grid
        item
        xs={10}
        sx={{ maxHeight: "100vh", overflow: "scroll", padding: "0px 10px !important"}}
      >
        {activeSection && sections[activeSection] && (
          <Box>
            <Accordion style={{ border: "1px solid #eee", paddingTop: "0px",}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="section-content"
                id="section-header"
              >
                <Typography
                  variant="h6"
                  style={{ fontSize: "21px", fontWeight: "600"}}
                >
                  {sections[activeSection].title}
                </Typography>

                <Typography sx={{ marginLeft: "auto",  }}>
                  <IconButton
                    style={{ color: "#000075" }}
                    color="primary"
                    size="small"
                    onClick={() => handleEdit(sections[activeSection])}
                  >
                    <EditIcon />
                  </IconButton>

                  <IconButton
                    color="error"
                    size="small"
                    onClick={() => handleDeleteSection(activeSection)}
                  >
                    <DeleteIcon />
                  </IconButton>

                  {/* <Deletemodal /> */}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* <IconButton
                  style={{ color: "#000075" }}
                  color="primary"
                  size="small"
                  onClick={() => handleEdit(sections[activeSection])}
                  sx={{ marginLeft: "auto" }}
                >
                  <EditIcon />
                </IconButton> */}
                {isEditing ? (
                  <Box>
                    <TextField
                      label="Title"
                      value={editedSection.title}
                      onChange={(e) =>
                        handleEditChange("title", e.target.value)
                      }
                      fullWidth
                      sx={{ mb: 2 }}
                      required
                    />
                    <TextField
                      label="Url"
                      value={editedSection.url}
                      onChange={(e) => handleEditChange("url", e.target.value)}
                      fullWidth
                      sx={{ mb: 2 }}
                      required
                    />
                    <TextField
                      label="Description"
                      value={editedSection.description}
                      onChange={(e) =>
                        handleEditChange("description", e.target.value)
                      }
                      fullWidth
                      sx={{ mb: 2 }}
                      required
                    />
                    <TextField
                      label="Keywords"
                      value={
                        Array.isArray(editedSection.keywords)
                          ? editedSection.keywords.join(", ")
                          : editedSection.keywords
                      }
                      onChange={(e) =>
                        handleEditChange("keywords", e.target.value)
                      }
                      fullWidth
                      sx={{ mb: 2 }}
                      required
                    />
                    <TextField
                      label="Meta_title"
                      value={editedSection.meta_title}
                      onChange={(e) =>
                        handleEditChange("meta_title", e.target.value)
                      }
                      fullWidth
                      sx={{ mb: 2 }}
                      required
                    />
                    <TextField
                      label="Meta_description"
                      value={editedSection.meta_description}
                      onChange={(e) =>
                        handleEditChange("meta_description", e.target.value)
                      }
                      fullWidth
                      sx={{ mb: 2 }}
                      required
                    />
                    <TextField
                      label="Headers"
                      value={
                        Array.isArray(editedSection.headers)
                          ? editedSection.headers.join(", ")
                          : editedSection.headers
                      }
                      onChange={(e) =>
                        handleEditChange("headers", e.target.value)
                      }
                      fullWidth
                      sx={{ mb: 2 }}
                      required
                    />

                    {editedSection.subpages &&
                      Object.keys(editedSection.subpages).map((subpageKey) => {
                        const subEditing =
                          editedSection.subpages[subpageKey].subEditing ||
                          false;
                        return (
                          <Accordion key={subpageKey} sx={{ marginTop: 2 }}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`subpage-${subpageKey}-content`}
                              id={`subpage-${subpageKey}-header`}
                            >
                              <Typography variant="subtitle1">
                                {editedSection.subpages[subpageKey].title}
                              </Typography>

                              {/* Delete Icon */}
                              <IconButton
                                color="error"
                                size="small"
                                onClick={(e) =>
                                  handleDeleteSubpage(subpageKey, e)
                                }
                                sx={{ marginLeft: "auto" }}
                              >
                                <DeleteIcon />
                              </IconButton>

                              {/* Edit Icon */}
                              <IconButton
                                color={subEditing ? "primary" : "default"}
                                size="small"
                                onClick={(e) =>
                                  handleEditSubpage(subpageKey, e)
                                }
                                sx={{ marginLeft: 1 }}
                              >
                                <EditIcon />
                              </IconButton>
                            </AccordionSummary>

                            <AccordionDetails>
                              {/* Subpage Title */}
                              <TextField
                                label="Subpage Title"
                                value={editedSection.subpages[subpageKey].title}
                                onChange={(e) =>
                                  handleSubpageEditChange(
                                    subpageKey,
                                    "title",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                sx={{ mb: 2 }}
                                disabled={!isEditing}
                              />

                              {/* Subpage URL */}
                              <TextField
                                label="Subpage Url"
                                value={editedSection.subpages[subpageKey].url}
                                onChange={(e) =>
                                  handleSubpageEditChange(
                                    subpageKey,
                                    "url",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                sx={{ mb: 2 }}
                                disabled={!isEditing}
                              />

                              {/* Subpage Description */}
                              <TextField
                                label="Subpage Description"
                                value={
                                  editedSection.subpages[subpageKey].description
                                }
                                onChange={(e) =>
                                  handleSubpageEditChange(
                                    subpageKey,
                                    "description",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                sx={{ mb: 2 }}
                                disabled={!isEditing}
                              />

                              {/* Subpage Keywords */}
                              <TextField
                                label="Subpage Keywords"
                                value={
                                  editedSection.subpages[subpageKey].keywords
                                }
                                onChange={(e) =>
                                  handleSubpageEditChange(
                                    subpageKey,
                                    "keywords",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                sx={{ mb: 2 }}
                                disabled={!isEditing}
                              />

                              {/* Subpage Meta Title */}
                              <TextField
                                label="Subpage Meta_title"
                                value={
                                  editedSection.subpages[subpageKey].meta_title
                                }
                                onChange={(e) =>
                                  handleSubpageEditChange(
                                    subpageKey,
                                    "meta_title",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                sx={{ mb: 2 }}
                                disabled={!isEditing}
                              />

                              {/* Subpage Meta Description */}
                              <TextField
                                label="Subpage Meta_description"
                                value={
                                  editedSection.subpages[subpageKey]
                                    .meta_description
                                }
                                onChange={(e) =>
                                  handleSubpageEditChange(
                                    subpageKey,
                                    "meta_description",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                sx={{ mb: 2 }}
                                disabled={!isEditing}
                              />

                              {/* Subpage Headers */}
                              <TextField
                                label="Subpage Headers"
                                value={
                                  editedSection.subpages[subpageKey].headers
                                }
                                onChange={(e) =>
                                  handleSubpageEditChange(
                                    subpageKey,
                                    "headers",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                sx={{ mb: 2 }}
                                disabled={!isEditing}
                              />
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}

                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#000075" }}
                      onClick={handleSaveEdit}
                      sx={{ marginTop: 2 }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "rgb(226 147 47)",
                        marginLeft: "10px",
                      }}
                      onClick={() => {
                        setIsEditing(false);
                      }}
                      sx={{ marginTop: 2 }}
                    >
                      Cancel
                    </Button>
                  </Box>
                ) : (
                  <Box>
                    <Typography variant="body1">
                      <strong>Title:</strong> {sections[activeSection].title}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Url:</strong> {sections[activeSection].url}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Description:</strong>{" "}
                      {sections[activeSection].description}
                    </Typography>

                    {/* <Typography variant="body1">
                      <strong>Keywords:</strong>
                    </Typography>
                    <ul>
                      {sections[activeSection].keywords.map(
                        (keyword, index) => (
                          <li key={index}>{keyword}</li>
                        )
                      )}
                    </ul> */}

                    {/* <Typography variant="body1">
                      <strong>Keywords:</strong>{" "}
                      {sections[activeSection].keywords}
                    </Typography> */}

                    <Typography variant="body1">
                      <strong>Keywords:</strong>
                      {Array.isArray(sections[activeSection].keywords)
                        ? sections[activeSection].keywords.join(", ")
                        : sections[activeSection].keywords}{" "}
                    </Typography>
                    {/* <ul>
                      {Array.isArray(sections[activeSection].keywords) ? (
                        sections[activeSection].keywords.map(
                          (keyword, index) => <li key={index}>{keyword}</li>
                        )
                      ) : (
                        <li>No keywords available</li>
                      )}
                    </ul> */}

                    <Typography variant="body1">
                      <strong>Meta_title:</strong>{" "}
                      {sections[activeSection].meta_title}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Meta_description:</strong>{" "}
                      {sections[activeSection].meta_description}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Headers:</strong>
                      {Array.isArray(sections[activeSection].headers)
                        ? sections[activeSection].headers.join(", ")
                        : sections[activeSection].headers}{" "}
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={() => setIsAddingSubpage(true)}
                      style={{ backgroundColor: "#000075" }}
                      sx={{ marginTop: 2, marginBottom: 2 }}
                    >
                      Add Subpage
                    </Button>
                    {isAddingSubpage && (
                      <Box sx={{ marginBottom: 2 }}>
                        <TextField
                          fullWidth
                          label="Subpage Title"
                          value={newSubpageTitle}
                          onChange={(e) => setNewSubpageTitle(e.target.value)}
                          sx={{ marginBottom: 2 }}
                        />

                        <TextField
                          fullWidth
                          label="Subpage URL"
                          value={newSubpageUrl}
                          onChange={(e) => setNewSubpageUrl(e.target.value)}
                          sx={{ marginBottom: 2 }}
                        />

                        <TextField
                          fullWidth
                          label="Subpage Description"
                          value={newSubpageDescription}
                          onChange={(e) =>
                            setNewSubpageDescription(e.target.value)
                          }
                          sx={{ marginBottom: 2 }}
                        />

                        <TextField
                          label="Subpage Keywords"
                          value={newSubpageKeywords}
                          onChange={(e) => setNewSubpageKeyword(e.target.value)}
                          fullWidth
                          sx={{ mb: 2 }}
                        />

                        <TextField
                          label="Subpage Meta_title"
                          value={newSubpageMeta_title}
                          onChange={(e) =>
                            setNewSubpageMetatitle(e.target.value)
                          }
                          fullWidth
                          sx={{ mb: 2 }}
                        />

                        <TextField
                          label="Subpage Meta_description"
                          value={newSubpageMetadescription}
                          onChange={(e) =>
                            setNewSubpageMetadescription(e.target.value)
                          }
                          fullWidth
                          sx={{ mb: 2 }}
                        />

                        <TextField
                          label="Subpage Headers"
                          value={newSubpageHeaders}
                          onChange={(e) => setNewSubpageHeaders(e.target.value)}
                          fullWidth
                          sx={{ mb: 2 }}
                        />

                        <Button
                          variant="contained"
                          onClick={handleSaveSubpage}
                          sx={{ marginRight: 2, backgroundColor: "#000075" }}
                        >
                          Save
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => setIsAddingSubpage(false)}
                        >
                          Cancel
                        </Button>
                      </Box>
                    )}

                    {sections[activeSection].subpages && (
                      <Box sx={{ marginTop: 2 }}>
                        {Object.keys(sections[activeSection].subpages).map(
                          (subpageKey) => (
                            <Accordion
                              key={subpageKey}
                              sx={{
                                marginBottom: 2,
                                backgroundColor: "#f5f5f5",
                                borderLeft: "4px solid #3f51b5",
                              }}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`${subpageKey}-content`}
                                id={`${subpageKey}-header`}
                              >
                                <Typography
                                  variant="subtitle1"
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {
                                    sections[activeSection].subpages[subpageKey]
                                      .title
                                  }
                                </Typography>
                                {/* <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() =>
                                    handleDeleteSubpage(subpageKey)
                                  }
                                  sx={{ marginLeft: "auto" }}
                                >
                                  <EditIcon />
                                </IconButton> */}
                                <IconButton
                                  color="error"
                                  size="small"
                                  onClick={() =>
                                    handleDeleteSubpage(subpageKey)
                                  }
                                  sx={{ marginLeft: "auto" }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box sx={{ paddingLeft: 2 }}>
                                  <Typography variant="body2">
                                    <strong>Title:</strong>{" "}
                                    {
                                      sections[activeSection].subpages[
                                        subpageKey
                                      ].title
                                    }
                                  </Typography>
                                  <Typography variant="body2">
                                    <strong>Url:</strong>{" "}
                                    {
                                      sections[activeSection].subpages[
                                        subpageKey
                                      ].url
                                    }
                                  </Typography>
                                  <Typography variant="body2">
                                    <strong>Description:</strong>{" "}
                                    {
                                      sections[activeSection].subpages[
                                        subpageKey
                                      ].description
                                    }
                                  </Typography>

                                  <Typography variant="body2">
                                    <strong>Keywords:</strong>
                                    {Array.isArray(
                                      sections[activeSection].subpages[
                                        subpageKey
                                      ].keywords
                                    )
                                      ? sections[activeSection].subpages[
                                          subpageKey
                                        ].keywords.join(", ")
                                      : sections[activeSection].subpages[
                                          subpageKey
                                        ].keywords}
                                  </Typography>
                                  {/* <ul>
                                    {sections[activeSection].subpages[
                                      subpageKey
                                    ].keywords.map((keyword, index) => (
                                      <li key={index}>{keyword}</li>
                                    ))}
                                  </ul> */}
                                  <Typography variant="body2">
                                    <strong>Meta_Title:</strong>{" "}
                                    {
                                      sections[activeSection].subpages[
                                        subpageKey
                                      ].meta_title
                                    }
                                  </Typography>
                                  <Typography variant="body2">
                                    <strong>Meta_Description:</strong>{" "}
                                    {
                                      sections[activeSection].subpages[
                                        subpageKey
                                      ].meta_description
                                    }
                                  </Typography>

                                  <Typography variant="body2">
                                    <strong>Headers:</strong>{" "}
                                    {Array.isArray(
                                      sections[activeSection].subpages[
                                        subpageKey
                                      ].headers
                                    )
                                      ? sections[activeSection].subpages[
                                          subpageKey
                                        ].headers.join(", ")
                                      : sections[activeSection].subpages[
                                          subpageKey
                                        ].headers}
                                  </Typography>
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                          )
                        )}
                      </Box>
                    )}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default Extra;
