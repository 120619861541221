import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Link,
  Grid,
  colors,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../src/sitemap_bg.webp";

const Register = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");

  const navigate = useNavigate();

  const Url = "https://aihumanizer.pranathiss.com/qxbox";

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a new FormData object
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password1", password);
    formData.append("password2", cpassword);

    // Optional: Log formData entries to verify
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const response = await axios.post(`${Url}/signup/`, formData, {
        headers: {
          // No need for "Content-Type" header; Axios will set it automatically for FormData
        },
      });

      navigate("/");
    } catch (error) {
      if (error.response) {
        console.error("Server responded with:", error.response.data);
      } else {
        console.error("Error during signup:", error);
      }
    }
  };

  return (
    <Grid container sx={{ height: "100vh" }}>
      {/* Blue Section */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundColor: "#000075",
          color: "white",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {" "}
        <Typography
          variant="h5"
          component="h1"
          gutterBottom
          align="left"
          style={{}}
          sx={{
            fontSize: "36px",
            color: "#ffffff",
            marginTop: "122px",
            fontSize: "46px",
            marginLeft: "54px",
          }}
        >
          Sitemap Generator
        </Typography>
        <Box
          component="p"
          sx={{
            fontSize: "16px",
            color: "#ffffff",
            marginLeft: "54px",
            width: "80%",
          }}
        >
          Signup in to streamline your site’s structure. Organize and generate
          sitemaps effortlessly. Signup in to streamline your site’s structure.
          Organize and generate sitemaps effortlessly...!
        </Box>
      </Grid>

      {/* Login Box Section */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Paper
          sx={{ padding: 4, width: "55%", maxWidth: "100%", boxShadow: "none" }}
        >
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
            align="left"
            sx={{ fontSize: "36px", color: " #000075" }}
          >
            Sitemap Generator Signup
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              margin="normal"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={{ marginY: 2 }}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ marginY: 2 }}
            />

            <TextField
              label="Confirm Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={cpassword}
              onChange={(e) => setCpassword(e.target.value)}
              sx={{ marginY: 2 }}
            />

            <Box sx={{ marginY: 2 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  backgroundColor: "#000075",
                  padding: "6px",
                  fontSize: "16px",
                }}
              >
                Signup
              </Button>
            </Box>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Register;
