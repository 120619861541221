import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Link,
  Grid,
  colors,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useAuth } from "./AuthContext";
import backgroundImage from "../src/sitemap_bg.webp";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [token, setToken] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);

  const { setToken } = useAuth();

  const navigate = useNavigate();

  const Url = "https://aihumanizer.pranathiss.com/qxbox";

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("username", username);
    formData.append("password", password);

    // console.log(formData);
    // console.log(username);
    // console.log(password);

    try {
      const response = await axios.post(`${Url}/login/`, formData);

      const { token } = response.data;

      // setToken(token);

      // console.log('Token:', token);

      if (token) {
        setToken(token);
        console.log("Token set in context:", token);
      }

      // const downloadResponse = await axios.get(`${Url}/download/`, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });s

      navigate("/extra");
    } catch (error) {
      console.error("Error during login or data fetching:", error);
    }
  };

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundColor: "#000075",
          color: "white",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Typography
          variant="h5"
          component="h1"
          gutterBottom
          align="left"
          style={{}}
          sx={{
            fontSize: "36px",
            color: "#ffffff",
            marginTop: "122px",
            fontSize: "46px",
            marginLeft: "54px",
          }}
        >
          Sitemap Generator
        </Typography>
        <Box
          component="p"
          sx={{
            fontSize: "16px",
            color: "#ffffff",
            marginLeft: "54px",
            width: "80%",
          }}
        >
          Log in to streamline your site’s structure. Organize and generate
          sitemaps effortlessly. Log in to streamline your site’s structure.
          Organize and generate sitemaps effortlessly...!
        </Box>
      </Grid>

      {/* Login Box Section */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Paper
          sx={{ padding: 4, width: "55%", maxWidth: "100%", boxShadow: "none" }}
        >
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
            align="left"
            sx={{ fontSize: "36px", color: " #000075" }}
          >
            Sitemap Generator Login
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              margin="normal"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={{ marginY: 2 }}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ marginY: 2, marginBottom: "10px" }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  color="primary"
                />
              }
              label="Remember me"
              sx={{ marginY: 0, marginBottom: "10px" }}
            />

            <Box sx={{ marginY: 2 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  backgroundColor: "#000075",
                  padding: "6px",
                  fontSize: "16px",
                }}
              >
                Login
              </Button>
            </Box>

            <Box sx={{ textAlign: "center", marginTop: 4 }}>
              {/* <Typography variant="body2" sx={{ fontSize: "18px" }}>
                Don't have an account?{" "}
                <Link
                  href="/register"
                  variant="body2"
                  sx={{ fontSize: "18px", color: "#000075" }}
                >
                  Signup
                </Link>
              </Typography> */}

              <Typography variant="body2" sx={{ fontSize: "18px" }}>
                Don't have an account?{" "}
                <Link
                  component="button"
                  onClick={() => navigate("/register")}
                  variant="body2"
                  sx={{ fontSize: "18px", color: "#000075" }}
                >
                  Signup
                </Link>
              </Typography>
              
            </Box>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Login;
